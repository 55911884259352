import React from 'react';

// Components
import { Wrapper, Inner, Form, AlertCard, SubmitButton } from './files';

// Context
import ContextConsumer from '../../contextProvider';

const EnquiryForm = () => (
  <ContextConsumer>
    {({ handleEnquirySubmit, successfulSend, errorSend }) => (
      <Wrapper>
        <Inner>
          <form
            name="enquiry"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleEnquirySubmit}
          >
            <input type="hidden" name="form-name" value="enquiry" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            <Form />
            {successfulSend && (
              <AlertCard className="success">
                <h4>
                  Your enquiry has been sent, please allow up to 24 hours for us
                  to reply.
                </h4>
              </AlertCard>
            )}
            {errorSend && (
              <AlertCard className="error">
                <h4>Please fill out all required fields.</h4>
              </AlertCard>
            )}
            <SubmitButton />
          </form>
          <p>Fields marked with a * are required.</p>
        </Inner>
      </Wrapper>
    )}
  </ContextConsumer>
);

export default EnquiryForm;
