import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import EnquiryForm from '../components/common/sections/enquireForm';

const EnquiryPage = ({ data }) => {
  const { seoContent } = data.contentfulPages;

  return (
    <>
      <SeoDetails whiteHero seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <EnquiryForm />
    </>
  );
};

export const query = graphql`
  query {
    contentfulPages(id: { eq: "8804db8e-506d-5e66-9933-29779631139e" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

export default EnquiryPage;
