import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../contextProvider';

const Buttons = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  align-items: center;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    grid-template-columns: 1fr;
    grid-gap: 23px;
  }
  button {
    transition: all 300ms ease-in-out;
    color: ${props => props.theme.colors.text.dark};
    font-weight: 700;
    text-transform: uppercase;
    font-size: 80%;
    height: 60px;
    border: none;
    cursor: pointer;
    &:first-child {
      background-color: ${props => props.theme.colors.primary};
      transition: all 300ms ease-in-out;
      &:hover {
        opacity: 0.98;
      }
    }
    &:last-child {
      color: ${props => props.theme.colors.text.light};
      background-color: ${props => props.theme.colors.background.dark};
      transition: all 300ms ease-in-out;
      &:hover {
        opacity: 0.98;
      }
    }
  }
`;

const SubmitButton = () => (
  <ContextConsumer>
    {({ enquiryVerified, handleEnquiryError, handleEnquiryReset }) => (
      <Buttons>
        <button
          type={enquiryVerified ? 'submit' : 'button'}
          onClick={enquiryVerified ? null : handleEnquiryError}
        >
          Send
        </button>
        <button type="reset" onClick={handleEnquiryReset}>
          Reset
        </button>
      </Buttons>
    )}
  </ContextConsumer>
);

export default SubmitButton;
